<template>
  <div class="overview kreativecke">

    <Navigation v-bind:showbackurl="true" backurl="/uebersicht"></Navigation>

    <div class="player" v-bind:class="{ play: status == 'play', paused: status == 'pause', ended: status == 'ended' }">
      <div>

        <div class="duration">Duration: {{ duration }} // Stat: {{ $store.state.count_overview_kreativecke }}</div>

        <div class="infos">

          <h1 class="title" v-bind:class="{ show: duration > 3 || $store.state.count_overview_kreativecke > 1 }">
            Die <span class="color1">Musen</span>...
          </h1>
          <h2 class="subtitle" v-bind:class="{ show: duration > 3.5 || $store.state.count_overview_kreativecke > 1 }">
            …und wie sie uns beleben
          </h2>

          <button class="link-play" v-on:click="PlayVideo" v-bind:class="{ show: (status != 'play' && duration == 0 && $store.state.count_overview_kreativecke == 1) }">Video Starten</button>

          <slot v-for="(link_item, index) in links">
            <router-link v-bind:class="[ `link`, `thumb` + link_item.videoThumbnailID, `kreativecke_link${index + 1}`, { show: duration > link_item.visibleAt || $store.state.count_overview_kreativecke > 1, 'link-thumbnail': link_item.videoThumbnailID }]" :to="link_item.link" v-if="(duration > (link_item.visibleAt - 1) || $store.state.count_overview_kreativecke > 1)">
              <span>{{ link_item.name }}</span>
              <vue-plyr-custom v-if="link_item.videoThumbnailID" :options="{autoplay: true, autopause: false, volume: 0, muted: true, loop: {active: true}, fullscreen: {enabled: false}, controls: []}">
                <div data-plyr-provider="vimeo" :data-plyr-embed-id="link_item.videoThumbnailID"></div>
              </vue-plyr-custom>
            </router-link>
          </slot>

          <div class="info_placeholder" v-bind:class="{ visible: $store.state.count_overview_kreativecke > 1 || status == 'ended' }">
            <img src="@/assets/thumbnails/kreativecke.jpg" />
          </div>

        </div>

        <vue-plyr-custom ref="plyr" :options="{autoplay: false, autopause: false, fullscreen: {enabled: false},controls: [ 'play', 'progress', 'current-time', 'mute', 'volume' ]}">
          <div data-plyr-provider="vimeo" data-plyr-embed-id="491585554"></div>
        </vue-plyr-custom>

      </div>
    </div>

    <Footer></Footer>

  </div>
</template>

<script>
import Navigation from '@/components/Navigation.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'KreativEcke',

  components: {
    Navigation,
    Footer
  },

  data() {
    return {
      duration: null,
      status: '',
      autoplay: true,
      audio: null,
      audiostop: false,
      links: [
        { name: 'Theater', link: '/video/theater', visibleAt: 28, videoThumbnailID: '490808096' },
        { name: 'Atelier', link: '/video/atelier', visibleAt: 30.75, videoThumbnailID: '490800804' },
        { name: 'Tanzen', link: '/video/tanzen', visibleAt: 33.25, videoThumbnailID: '490807919' },
        { name: 'Werken', link: '/video/werken', visibleAt: 35.650, videoThumbnailID: '490808459' }
      ]
    };
  },

  beforeMount() {
    this.$store.commit('increment_overview_kreativecke')
    this.autoplay = this.$store.state.count_overview_kreativecke == 1
  },

  mounted() {
    if (this.$refs.plyr) {
      if (this.$refs.plyr.player) {
        this.$refs.plyr.player.on('ready', () => this.onReady())
        this.$refs.plyr.player.on('play', () => this.onPlay())
        this.$refs.plyr.player.on('pause', () => this.onPause())
        this.$refs.plyr.player.on('ended', () => this.onEnded())
        this.$refs.plyr.player.on('timeupdate', () => this.videoTimeUpdated())
      }
    }

    var self = this
    this.audiostop = false

    if (this.$store.state.count_overview_kreativecke > 1) {
      if (this.$store.state.count_overview_kreativecke == 2) {
        this.audio = new Audio(require('@/assets/audio/Kreative_Ecke-Helena Hauptmenue_Reminder_1a.mp3'))
        setTimeout(function() { if (!self.audiostop) { self.audio.play() } }, 3000);
      }
    }
  },

  beforeDestroy() {

    this.$store.commit('page_transition')

    if (this.audio) {
      this.audio.pause()
      this.audio.currentTime = 0
      this.audiostop = true
    }

    if (this.$refs.plyr) {
      if (this.$refs.plyr.player) {
        this.$refs.plyr.player.stop()
        this.$refs.plyr.player.destroy()
      }
    }

  },

  methods: {
    PlayVideo() {
      this.$refs.plyr.player.embed.play()
    },
    onReady() {
      if (this.autoplay) {
        this.$refs.plyr.player.embed.play()
      }
    },
    onPlay() {
      this.status = 'play'
    },
    onPause() {
      this.status = 'pause'
    },
    onEnded() {
      this.status = 'ended'
    },
    videoTimeUpdated: function() {

      if (this.$refs.plyr) {
        if (this.$refs.plyr.player) {
          this.duration = this.$refs.plyr.player.currentTime;
        }
      }

      this.links.forEach((link_item, index) => {
        if ( this.duration >=  ( link_item.visibleAt ) && this.duration <= ( link_item.visibleAt + (this.$root.soundbit_sound_duration) )  ) {
          this.$root.playSoundbit(index)
        }
      })

    }
  }

}
</script>